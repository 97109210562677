import { CheckIcon } from "@heroicons/react/16/solid";

import { Button } from "./Button";
import CreateNewKey from "./nodes-create-new-key-dialog";

function StepCircle({ step }: { step: number }) {
  return (
    <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-black/5">
      <span className="text-system-m-plus tabular-nums leading-none text-grey-80">
        {step}
      </span>
    </div>
  );
}

function CheckCircle() {
  return (
    <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-blue-60">
      <CheckIcon className="size-5 text-white" />
    </div>
  );
}

export function SuggestedCardItemDescription({
  text,
  number: step,
  isComplete = false,
}: {
  text: string;
  number: number;
  isComplete?: boolean;
}) {
  return (
    <div className="flex items-center justify-start gap-4 text-grey-80">
      {isComplete ? <CheckCircle /> : <StepCircle step={step} />}

      <span className="text-system-m">{text}</span>
    </div>
  );
}

export default function GetStartedRpcKeyCard() {
  return (
    <div className="max-w-sm divide-y-0.5 rounded-xl border-0.5 bg-white shadow-card">
      <div className="px-6 py-4">
        <h2 className="mb-1 text-system-xl text-black">
          Get started with Conduit Nodes
        </h2>

        <p className="text-system-m text-grey-80">
          Use keys to access the RPC endpoints of rollups deployed on Conduit.
        </p>
      </div>

      <div className="p-6 pt-5">
        <div className="space-y-4">
          <SuggestedCardItemDescription
            number={1}
            text="Choose a name for your key"
          />

          <SuggestedCardItemDescription
            number={2}
            text="Select the rollups you would like to access and build on"
          />

          <SuggestedCardItemDescription
            number={3}
            text="Copy the RPC endpoint URL and your key"
          />

          <SuggestedCardItemDescription
            number={4}
            text="Start sending requests to the rollup RPC endpoint"
          />
        </div>

        <CreateNewKey rpcKeysCount={0}>
          <Button test-id="open_dialog" className="mt-5 w-full">
            Create new key
          </Button>
        </CreateNewKey>
      </div>
    </div>
  );
}
