import { CheckIcon } from "@heroicons/react/20/solid";
import type { ComponentProps } from "react";
import { forwardRef, useMemo } from "react";

import type { Organization } from "../apis/api-types";
import SquareAvatar from "./SquareAvatar";

interface OrganizationItemProps extends ComponentProps<"button"> {
  organization: Organization;
  isActive?: boolean;
}

export const OrganizationItem = forwardRef<
  HTMLButtonElement,
  OrganizationItemProps
>(({ organization, isActive, ...rest }, ref) => {
  const fallback = useMemo(() => {
    return organization.name ? organization.name.slice(0, 1).toUpperCase() : "";
  }, [organization.name]);

  return (
    <button
      ref={ref}
      className="flex w-full items-center gap-2.5 rounded p-2 text-left transition-colors hover:bg-grey-10 focus:bg-grey-10 focus:outline-none"
      test-id="OrganizationItem"
      {...rest}
    >
      <SquareAvatar
        className={isActive ? "bg-accent" : "bg-grey-50"}
        fallback={fallback}
      />

      <p className="flex-1 truncate text-system-m text-black">
        {organization.name ?? "..."}
      </p>

      {isActive ? <CheckIcon className="size-4 text-accent" /> : null}
    </button>
  );
});

export const OrganizationItemSkeleton = () => {
  return (
    <div className="flex w-full items-center gap-2.5 p-2">
      <div className="skeleton-loader size-6 shrink-0 rounded" />
      <div className="skeleton-loader my-1 h-3.5 w-3/4 rounded"></div>
    </div>
  );
};

OrganizationItem.displayName = "OrganizationItem";
